<template>
  <div class="article-tags">

  <div v-if="tags && tags.length" class="article-tags__tags">
    <div v-for="tag in tags" :key="tag" class="article-tags__tag">
      {{ tag }}
    </div>
  </div>
</div>
</template>

<script>
import topLevelTags from '../../constants/topLevelTags';


export default {
    data() {
    return {
      tags: [],
    };
  },
  mounted() {
    const keywordsMetaTag =
      document.querySelector('meta[name="keywords"]');

    const keywords = keywordsMetaTag?.content
      ? keywordsMetaTag.getAttribute('content').split(',')
      : [];

    this.tags = keywords.filter((k) => k !== "Featured" && k !== "Homepage" && !topLevelTags.includes(k));
  }
};
</script>

<style scoped></style>
