const EventType = {
    firebaseEvent: 'firebaseEventSent',
    resizeWindow: 'resizeWindowResponse',
};

const defaultResizePayload =
    {
        event: 'resizeWindow',
        param: '{"height":536}'
    }

const lazyLoadIframes = () => {
    console.log("loading iframe")
    const iframes = document.querySelectorAll('iframe[data-src]');
    iframes.forEach((iframe) => {
        iframe.src = iframe.dataset.src;
    });
};

const CLASSES = {
    thirdPartyExperienceComponent: 'cmp-embed__third-party-experience',
};

const events = {
    sendFirebaseEvent: function(payload) {
        var name = payload.name;
        var params = payload.params;

        var events = { data: params };
        // sendAnalyticsEvent(payload, name, events);

        var firebaseResponse = {
            original_event_name: name,
            message: 'Firebase event successfully sent',
        };
        postMessage(payload, {
            event: EventType.firebaseEvent,
            data: firebaseResponse,
        });
    },
    resizeWindow: async function (payload) {
        const { height } = JSON.parse(payload.param);
        let response;
        if (!Number.isInteger(height) || !(height > 0)) {
            response = { error: 'bad_input', message: 'height must be an integer and greater than zero' };
        }
        else {
            const iframes = document.querySelectorAll(`.${CLASSES.thirdPartyExperienceComponent}`);
            if(iframes){
                iframes.forEach((_iframe) => {
                    _iframe.style.height = `${height}px`;
                    _iframe.style.aspectRatio = 'unset';
                });
                response = { message: `height set to ${height}px` };
            }
        }

        const responseMessage = {
            event: EventType.resizeWindow,
            payload: response,
        };
        postMessage(payload, responseMessage);
    }
}

const isJSON = (message) => {
    try {
        return !!JSON.parse(message);
    } catch (error) {
        return false;
    }
};

const processMessage = function (payload) {
    if (isJSON(payload)) {
        var data = JSON.parse(payload);
        if (data.event in events && typeof events[data.event] === 'function') {
            events[data.event](data);
        }
    }
};

let iframe;
const setupIframeCommunication = async function () {
    window.addEventListener('message', function (event) {
        processMessage(event.data);
    });
    iframe = document.querySelector('.' + CLASSES.thirdPartyExperienceComponent + ' iframe');
};

const setDefaultIframeHeight = function() {
    // TODO Need to come up with a better solution  than defaulting to 536px for the height.
    const iframes = document.querySelectorAll(`.${CLASSES.thirdPartyExperienceComponent}`);
    if(iframes){
        iframes.forEach((_iframe) => {
            _iframe.style.height = `536px`;
            _iframe.style.aspectRatio = 'unset';
        });
        response = { message: `height set to 536px` };
    }
}

function init() {
    lazyLoadIframes();
    setupIframeCommunication();
    setDefaultIframeHeight();
}

if (document.readyState !== 'loading') {
    init();
} else {
    document.addEventListener('DOMContentLoaded', init);
}

