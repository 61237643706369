import { createApp, h } from 'vue';
import FeaturedInsightsArticles from './FeaturedInsightsArticles.vue';

const initFeaturedInsightsArticles = () => {
  const FeaturedInsightsArticlesContainer = document.querySelector('#featured-insights');
    const app = createApp({
      render: () => {
        return h(FeaturedInsightsArticles);
      },
    });


    app.mount(FeaturedInsightsArticlesContainer);
};

if (document.readyState !== 'loading') {
  initFeaturedInsightsArticles();
} else {
  document.addEventListener('DOMContentLoaded', initFeaturedInsightsArticles);
}