import { createApp, h } from 'vue';
import RelatedArticles from './RelatedArticles.vue';

const initRelatedArticles = () => {
  const relatedArticlesContainer = document.querySelector('#related-articles');

    const app = createApp({
      render: () => {
        return h(RelatedArticles);
      },
    });


    app.mount(relatedArticlesContainer);
};

if (document.readyState !== 'loading') {
  initRelatedArticles();
} else {
  document.addEventListener('DOMContentLoaded', initRelatedArticles);
}