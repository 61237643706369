<template>
  <div class="article-teasers">
    <div
      :class="`article-teasers__${carouselOnMobile ? 'mobile' : 'desktop'}-carousel article-teasers__carousel`"
      v-if="carouselOnMobile || carouselOnDesktop"
    >
      <Carousel :items-to-show="itemsToShow">
        <Slide v-for="(article, index) in articles" :key="index">
          <Teaser
            :title="article.fields.title"
            :description="article.fields.description"
            :pretitle="article.fields.pretitle"
            :tags="article.fields.keywords"
            :cta="{ text: 'Read', link: article.fields.path }"
            :image="{
              url: article.fields.thumbnail_image,
              alt: article.fields.thumbnail_alt_text,
            }"
          />
        </Slide>
        <template #addons>
          <Navigation />
          <Pagination />
        </template>
      </Carousel>
    </div>
    <div
      class="article-teasers__desktop"
      v-if="!carouselOnDesktop"
      :class="{
        'article-teasers__desktop--no-carousel': !carouselOnMobile && !carouselOnDesktop,
        'article-teasers__desktop--two-up': articles.length <= 2 && !forceThreeUp,
      }"
    >
      <Teaser
        v-for="(article, index) in articles.slice(0, articles.length)"
        :filter-top-level="this.filterTopLevel"
        :key="index"
        :title="article.fields.title"
        :description="article.fields.description"
        :pretitle="article.fields.pretitle"
        :tags="article.fields.keywords"
        :cta="{ text: 'Read', link: article.fields.path }"
        :image="{
          url: article.fields.thumbnail_image,
          alt: article.fields.thumbnail_alt_text,
        }"
      />
    </div>
  </div>
</template>

<script>
import { defineComponent, ref, onMounted, onBeforeUnmount } from 'vue';
import { Carousel, Pagination, Slide } from 'vue3-carousel';
import Teaser from './../teaser/Teaser.vue';

import 'vue3-carousel/dist/carousel.css';

export default defineComponent({
  name: 'Basic',
  components: {
    Carousel,
    Slide,
    Pagination,
    Teaser,
  },
  props: {
    forceThreeUp: {
      type: Boolean,
      required: false,
      default: false,
    },
    carouselOnMobile: {
      type: Boolean,
      required: false,
      default: false,
    },
    carouselOnDesktop: {
      type: Boolean,
      required: false,
      default: false,
    },
    articles: {
      type: Array,
      required: true,
    },
    filterTopLevel: {
      type: Boolean,
      default: true,
    },
  },
  setup(props) {
    const itemsToShow = ref(props.carouselOnDesktop ? 3 : 1);

    const updateItemsToShow = () => {
      itemsToShow.value = window.innerWidth <= 577 ? 1 : (props.carouselOnDesktop ? 3 : 1);
    };

    onMounted(() => {
      updateItemsToShow();
      window.addEventListener('resize', updateItemsToShow);
    });

    onBeforeUnmount(() => {
      window.removeEventListener('resize', updateItemsToShow);
    });

    return { itemsToShow };
  },
});
</script>

<style></style>
