<template>
  <ArticleTeasers :articles="articles" :carousel-on-desktop="true" :force-three-up="true" />
</template>

<script>
import ArticleTeasers from './../article-teasers/ArticleTeasers.vue';
import { makeCloudSearchRequest } from './../../services/makeCloudSearchRequest';

export default {
  components: {
    ArticleTeasers,
  },
  data() {
    return {
      articles: [],
    };
  },
  mounted() {
    const keywordsMetaTag = document.querySelector('meta[name="keywords"]');
    if (keywordsMetaTag) {

    const keywords = keywordsMetaTag.getAttribute('content').split(',').filter(keyword => {
        return keyword !== "Homepage" && keyword !== "Featured";
    });

      makeCloudSearchRequest(keywords, 0, 1000, 'Article')
        .then((data) => {
          // Filter out the current page
          this.articles = data.hits.hit.filter((hit) => {
            const currentPath = window.location.href;
            const targetPath = hit.fields.path;
            return currentPath.indexOf(targetPath) === -1;
          });
        })
        .catch((error) => {
          console.error('Error fetching articles:', error);
        });
    } else {
      console.warn('No meta tag with name "keywords" found.');
    }
  },
};
</script>

<style scoped></style>
