import { createApp, h } from 'vue';
import FeaturedArticles from './FeaturedArticles.vue';

const initFeaturedArticles = () => {
  const FeaturedArticlesContainer = document.querySelector('#featured-articles');
    const app = createApp({
      render: () => {
        return h(FeaturedArticles);
      },
    });


    app.mount(FeaturedArticlesContainer);
};

if (document.readyState !== 'loading') {
  initFeaturedArticles();
} else {
  document.addEventListener('DOMContentLoaded', initFeaturedArticles);
}