import { createApp, h } from 'vue';
import FeaturedPerspectivesArticles from './FeaturedPerspectivesArticles.vue';

const initFeaturedPerspectivesArticles = () => {
  const FeaturedPerspectivesArticlesContainer = document.querySelector('#featured-perspectives');
  const app = createApp({
      render: () => {
        return h(FeaturedPerspectivesArticles);
      },
    });


    app.mount(FeaturedPerspectivesArticlesContainer);
};

if (document.readyState !== 'loading') {
  initFeaturedPerspectivesArticles();
} else {
  document.addEventListener('DOMContentLoaded', initFeaturedPerspectivesArticles);
}