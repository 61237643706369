import Swiper from "swiper";
import { Autoplay, Pagination } from "swiper/modules";
import "swiper/swiper.min.css";

const loadCarousel = () => {
  const carouselCmpList = document.querySelectorAll(".carousel");

  // If not exists any carousel component
  if (carouselCmpList === null) {
    return;
  }

  // Initialize all the carousels
  carouselCmpList.forEach((carouselCmp) => {
    const carouselContainer = carouselCmp.querySelector(".cmp-carousel");

    const carouselContent = carouselCmp.querySelector(".cmp-carousel__content");

    if (!carouselContent || !carouselContainer) return;

    const slides = carouselCmp.querySelectorAll(".cmp-carousel__item");

    const pagination = carouselCmp.querySelector(".cmp-carousel__indicators");
    pagination?.remove();

    const actions = carouselCmp.querySelector(".cmp-carousel__actions");
    actions?.remove();

    carouselContainer.classList.add("swiper");
    carouselContainer.insertAdjacentHTML(
      "beforeend",
      '<div class="swiper-pagination"></div>'
    );

    carouselContent.classList.add("swiper-wrapper");

    slides.forEach((slide) => {
      slide.classList.add("swiper-slide");
    });

    const isAutoplay = carouselContainer.hasAttribute("data-cmp-autoplay");
    const autoplayDelayValue = carouselContainer.getAttribute("data-cmp-delay");

    const swiper = new Swiper(carouselContainer, {
      autoplay: isAutoplay ? {
        delay: autoplayDelayValue,
        disableOnInteraction: false,
      } : false,
      modules: [Autoplay, Pagination],
      centeredSlides: true,
      spaceBetween: 24,
      slidesPerView: 1.2,
      loop: slides.length > 2 ? true : false,

      pagination: {
        el: ".swiper-pagination",
        clickable: true,
        renderBullet: function (index, className) {
          return (
            '<button aria-label="Go to slide ' +
            (index + 1) +
            '" class="' +
            className +
            '"></button>'
          );
        },
      },

      breakpoints: {
        768: {
          slidesPerView: 1.12,
        },
      },
    });

    const isDisabledOnHover = carouselContainer.hasAttribute("data-cmp-autopause-disabled");
    if (isAutoplay && isDisabledOnHover) {
      carouselContainer.addEventListener('mouseenter', (e) => {
        swiper.autoplay.stop();
      })
      carouselContainer.addEventListener('mouseleave', (e) => {
        swiper.autoplay.start();
      })
    }

  });
};

if (document.readyState !== "loading") {
  loadCarousel();
} else {
  document.addEventListener("DOMContentLoaded", loadCarousel);
}
