<template>
  <ArticleTeasers :articles="articles" :carousel-on-desktop="true" :filter-top-level="false" :force-three-up="true" />
</template>

<script>
import ArticleTeasers from './../article-teasers/ArticleTeasers.vue';
import { makeCloudSearchRequest } from './../../services/makeCloudSearchRequest';

export default {
  components: {
    ArticleTeasers,
  },
  data() {
    return {
      articles: [],
    };
  },
  mounted() {
    const keywords = ['Insights', 'Homepage'];

    makeCloudSearchRequest(keywords, 0, 1000, "Article", true)
      .then((data) => {
        this.articles = data.hits.hit;
      })
      .catch((error) => {
        console.error('Error fetching articles:', error);
      });
  }
};
</script>

<style scoped></style>
