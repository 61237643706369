import { createApp, h } from 'vue';
import AllArticles from './AllArticles.vue';

const initAllArticles = () => {
  const AllArticlesContainer = document.querySelector('#all-articles');
    const app = createApp({
      render: () => {
        return h(AllArticles);
      },
    });


    app.mount(AllArticlesContainer);
};

if (document.readyState !== 'loading') {
  initAllArticles();
} else {
  document.addEventListener('DOMContentLoaded', initAllArticles);
}