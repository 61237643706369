import { createApp, h } from 'vue';
import ArticleTags from './ArticleTags.vue';

const initArticleTags = () => {
  const ArticleTagsContainer = document.querySelector('#article-tags-container');
    const app = createApp({
      render: () => {
        return h(ArticleTags);
      },
    });


    app.mount(ArticleTagsContainer);
};

if (document.readyState !== 'loading') {
  initArticleTags();
} else {
  document.addEventListener('DOMContentLoaded', initArticleTags);
}