
// Stylesheets
import "./theme.scss";
import "./resources/images/favicon.png"

// Component Javascript
import "./components/experiencefragment/change_flag.js";
import "./components/form-options/form_dropdown.js";
import "./components/progressbar/progressbar.js";
import "./components/header/header.js";
import "./components/carousel/carousel.js";
import "./components/embed/embed-third-party.js"


// Page javascript
import "./pages/category-and-tag-search/category-and-tag-search.js";
import "./components/related-articles/related-articles.js";
import "./components/featured-articles/featured-articles.js";
import "./components/featured-insights-articles/featured-insights-articles.js";
import "./components/featured-perspectives-article/featured-perspectives-articles.js";

import "./components/all-articles/all-articles.js";
import "./components/article-tags/article-tags.js";

// favicons
import "./resources/favicons/apple-touch-icon.png"
import "./resources/favicons/favicon-32x32.png"
import "./resources/favicons/favicon-16x16.png"
import "./resources/favicons/safari-pinned-tab.svg"