import { createApp, h } from 'vue';
import CategoryAndTagSearch from './../../pages/category-and-tag-search/CategoryAndTagSearch.vue';

const CONTAINER_ID = 'category-and-tag-search';
const TEMPLATE = 'category-and-tag-search';

const initCategoryAndTagSearch = () => {
  const metaTag = document.querySelector(
    `meta[name="template"][content="${TEMPLATE}"]`
  );

  if (!metaTag) return;

  const categoryAndSearch = document.getElementById(CONTAINER_ID);
    
  const app = createApp({
    render: () => {
      return h(CategoryAndTagSearch);
    },
  });

  app.mount(categoryAndSearch);
};

if (document.readyState !== 'loading') {
  initCategoryAndTagSearch();
} else {
  document.addEventListener('DOMContentLoaded', initCategoryAndTagSearch);
}
