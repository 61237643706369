<template>
  <ArticleTeasers :articles="articles" />
</template>

<script>
import ArticleTeasers from './../article-teasers/ArticleTeasers.vue';
import { makeCloudSearchRequest } from './../../services/makeCloudSearchRequest';

export default {
  components: {
    ArticleTeasers,
  },
  data() {
    return {
      articles: [],
    };
  },
  mounted() {
    const keywordsMetaTag = document.querySelector('meta[name="keywords"]');
    const keywords = ['Featured', ...keywordsMetaTag?.getAttribute('content').split(',')];

    makeCloudSearchRequest(keywords, 0, 2, "Article", true)
      .then((data) => {
        this.articles = data.hits.hit.filter((hit) => {
          const currentPath = window.location.href;
          const targetPath = hit.path;
          return currentPath.indexOf(targetPath) === -1;
        });
      })
      .catch((error) => {
        console.error('Error fetching articles:', error);
      });
  }
};
</script>

<style scoped></style>
