<template>
  <div>Category and Tag Search Page</div>
  <div class="container">
    <div class="split-50-50">
      <div class="cmp-container">
        <Teaser
          :title="`title`"
          :description="`description`"
          :pretitle="`pretitle`"
          :tags="['tag1', 'tag2', 'tag3']"
          :cta="{ text: 'Read', link: '#' }"
          :image="{
            url: 'https://via.placeholder.com/1024x768/eee?text=4:3',
            alt: 'alt text',
          }" />
        <Teaser
          :title="`title`"
          :description="`description`"
          :pretitle="`pretitle`"
          :tags="['tag1', 'tag2', 'tag3']"
          :cta="{ text: 'Read', link: '#' }"
          :image="{
            url: 'https://via.placeholder.com/1024x768/eee?text=4:3',
            alt: 'alt text',
          }" />
      </div>
    </div>
  </div>
</template>

<script>
import Teaser from '../../components/teaser/Teaser.vue';
export default {
  components: {
    Teaser,
  },
  data() {
    return {};
  },
};
</script>

<style scoped></style>
